<template>
  <div>
    <h2><center>System Config</center></h2>
    <br/><br/>
    <SystemConfigAddNew
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
    />
    <b-card
      no-body
      class="mb-0"
    >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >

      </b-col>
      <b-col
        cols="12"
        md="6"
      >
      <div class="d-flex align-items-center justify-content-end">
        
        <b-button
        v-if="!this.adminSystemConfig[0]"
          variant="primary"
          @click="isAddNewUserSidebarActive = true"
        >
          <span class="text-nowrap">Add SystemConfig</span>
        </b-button>
      </div>
      </b-col>
      </b-row>
    </div>
    <div v-if="modalShow">
      <b-modal
        ref="refEditMerchant"
        title="Edit System Config!"
        v-model="modalShow"
        @hidden="resetModal"
        @ok="updateAdminConfig"
        ok-title="Save"
        ok-only

      >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group

          id="input-group-warehouse-address"
          label="Ware house address"
          label-for="Ware-house-address"
          invalid-feedback="Ware house address is required"
          :state="editConfigState"

        >
          <b-form-input
            class="forminput"
            id="ware-house-address"
            v-model="systemconfig.warehouseAddress"
            autofocus
            :state="editConfigAddressState"
            aria-describedby="input-warehouse-address"
            placeholder="Address"
            required

          />
          <b-form-invalid-feedback id="input-warehouse-address" v-if="systemconfig.warehouseAddress==''">
            Enter Warehouse Address
          </b-form-invalid-feedback>

        </b-form-group>
        <b-form-group

          id="input-group-maxpackage-address"
          label="MaxPackageValue"
          label-for="MaxPackageValue"
          invalid-feedback="MaxPackageValue is required"

        >
          <b-form-input
            class="forminput"
            id="MaxPackageValue"
            v-model="systemconfig.maxPackageValue"
            :state="editConfigmaxPackState"
            type = "number"
            step="0.001" min="0.000" max="1000.000"
            aria-describedby="input-max-package-value"
            trim
          />
          <b-form-invalid-feedback id="input-max-package-value" v-if="systemconfig.maxPackageValue==0">
            Enter max package value
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-maxpackage-address"
          label="Consolidation Fees"
          label-for="Consolidation Fees"
          invalid-feedback="Consolidation Fees is required"
          :state="editConfigConsolidationFees"
        >
          <b-form-input
            class="forminput"
            id="ConsolidationFees"
            v-model="systemconfig.consolidationFees"
            :state="editConfigConsolidationFees"
            type = "number"
            step="0.001" min="0.000" max="1000.000"
            aria-describedby="input-consolidation-fees"
            trim
          />
          <b-form-invalid-feedback id="input-consolidation-fees" v-if="systemconfig.consolidationFees==0">
            Enter Consolidation Fees
          </b-form-invalid-feedback>
        </b-form-group>
      </form>

      </b-modal>
    </div>



    <b-table
      ref="refAdminSystemConfig"
      class="position-relative"
      :items="adminSystemConfig"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
    >
    <template #cell(warehouseAddress)="data">
      <div class="text-nowrap">
        {{ data.item.warehouseAddress }}
      </div>
    </template>
    <template #cell(maxPackageValue)="data">
      <div class="text-nowrap">
        {{ data.item.maxPackageValue }}
      </div>
    </template>
    <template #cell(consolidationFees)="data">
      <div class="text-nowrap">
        {{ data.item.consolidationFees }}
      </div>
    </template>
    <template #cell(actions)="data">
      <b-dropdown
        variant="link"
        no-caret
        :right="$store.state.appConfig.isRTL"
      >

        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item @click="editSystemConfig(data.item)">
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>

        <b-dropdown-item @click="deleteSystemConfig(data.item.id)">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Delete</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>

  </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BPopover
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import SystemConfigAddNew from './systemConfigAddNew.vue'
import { mapMutations, mapActions, mapState } from "vuex";
import BCardCode from '@core/components/b-card-code';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
export default {
  name:"adminSystemConfig",
  data(){
    return {
      tableColumns :[
        { key: 'warehouseAddress'},
        {key:'maxPackageValue'},
        { key: 'consolidationFees' },
        { key: 'actions' }
      ],

      isAddNewUserSidebarActive:false,
      refAdminSystemConfig:"systemConfig",
      modalShow : false,

      systemconfig:{
        warehouseAddress:'',
        maxPackageValue:0,
        consolidationFees:0
      },


    }
  },
  directives: {
    Ripple
  },
  setup(props, { emit }) {

    let address = ref('');
    let systemconfig = {
      warehouseAddress:'',
      maxPackageValue:0,
      consolidationFees:0
    };
    // let isActive = false;
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(systemconfig)

    return {
      systemconfig,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
 
  computed:{
    ...mapState({
      adminSystemConfig :(state) =>{
       return state.adminsystemConfig.adminSystemConfig;
     },

   }),
   editConfigAddressState(){
     return this.systemconfig.warehouseAddress !=0 ? true : false
   },
   editConfigmaxPackState(){
     return this.systemconfig.maxPackageValue > 0 ? true : false
   },
   editConfigConsolidationFees(){
     return this.systemconfig.consolidationFees > 0 ? true : false
   }

  },
  async mounted(){
    var comp = this;
    await comp.loadSystemConfig()
    
  },

  methods:{

    ...mapActions({
      loadSystemConfig:"adminsystemConfig/fetchAllSystemConfigs",
      deleteSystemConfig:"adminsystemConfig/deleteSystemConfig",
      updateSystemConfig:"adminsystemConfig/editSystemConfig"
    }),
    getAddressData: function (addressData, placeResultData, id) {
      console.log("addressData info"+JSON.stringify(addressData))
      //this.systemconfig.warehouseAddress = addre
    },
    // updateConfig(){
    //   console.log("System Config info=="+JSON.stringify(this.systemconfig))
    //   this.updateSystemConfig(this.systemconfig)
    //
    // },
    editSystemConfig(configInfo){
      this.modalShow = true
      this.systemconfig = configInfo;
      console.log("editSystemConfig info()"+JSON.stringify(this.systemconfig))
    },
    resetModal() {
      // this.selectedMerchant = null
    //  this.editConfigState = null
    },
    updateAdminConfig(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        //this.editConfigState = valid
        return valid
      },
    handleSubmit() {
      // Exit when the form isn't valid
      // if (!this.checkFormValidity()) {
      //   return
      // }
      if (this.systemconfig.warehouseAddress!='' && this.systemconfig.maxPackageValue>0 && this.systemconfig.consolidationFees>0) {
        this.updateSystemConfig(this.systemconfig)
        this.$nextTick(() => {
          this.modalShow = false
        })
      }

    },
   
  },
  components: {
    SystemConfigAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BPopover,
    vSelect,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.forminput {
  margin-top: 10px;
}
</style>
