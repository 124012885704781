<template style="z-index:1000">
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"


  >
  <template #default="{ hide }">
    <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
      <h5 class="mb-0">
        Add System Config
      </h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="hide"
      />
      </div>


      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- Address -->


        <validation-provider
          #default="validationContext"
          name="Ware house address"
          rules="required"
        >
          <b-form-group
            label="Ware house address"
            label-for="Ware-house-address"
          >
            <b-form-input
              id="ware-house-address"
              v-model="systemconfig.warehouseAddress"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Address"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="MaxPackageValue"
          rules="required"
        >
          <b-form-group
            label="MaxPackageValue"
            label-for="MaxPackageValue"
          >
            <b-form-input
              id="MaxPackageValue"
              v-model="systemconfig.maxPackageValue"
              :state="getValidationState(validationContext)"
              type = "number"
              step="0.001" min="0.000" 
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="consolidationFees"
          rules="required"
        >
          <b-form-group
            label="Consolidation Fees"
            label-for="Consolidation Fees"
          >
            <b-form-input
              id="consolidationFees"
              v-model="systemconfig.consolidationFees"
              :state="getValidationState(validationContext)"
              type = "number"
              step="0.001" min="0.000" 
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Add
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
      </validation-observer>





  </template>

</b-sidebar>
</template>
<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

import { mapMutations, mapActions, mapState } from "vuex";
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    }

  },
  computed:{
    ...mapState({

   })

  },

  data(){
    return {

      systemconfig:{
        warehouseAddress:'',
        maxPackageValue:0,
        consolidationFees:0
      },
      address:""
    }
  },
  directives: {
    Ripple
  },
  setup(props, { emit }) {

    let address = ref('');
    let systemconfig = {
      warehouseAddress:'',
      maxPackageValue:0,
      consolidationFees:0
    };
    // let isActive = false;
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(systemconfig)

    return {
      systemconfig,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },

  mounted() {

    },

  methods:{

    ...mapActions({
      addSystemConfig:'adminsystemConfig/addAdminSystemConfig'
    }),
    onSubmit(){
      console.log("System Config info=="+JSON.stringify(this.systemconfig))
      this.addSystemConfig(this.systemconfig)

    },
  }
}
</script>
<style>
.autocomplete {
 margin-bottom: 2rem
}
.pac-container {
 z-index: 1100 !important;
}
</style>
